@import (reference) "./colors.less";
@import (reference) "./typography.less";

.input() {
  max-width: 260px;
  background-color: @white;
  padding: 5px 9px;
  border: 1px solid @gray;
  border-radius: 1px;
  .paragraph();
  outline: none;

  &::placeholder {
    color: rgba(@gray-medium, 0.4);
  }

  &.big {
    max-width: none;
    max-width: unset;
    background-color: transparent;
    padding: 0;
    padding-bottom: 10px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid @pewter-light;
    font-size: 18px;

    &:focus {
      border-bottom-width: 2px;
    }
  }

  &:hover {
    border-color: darken(@fog, 15%);
    &:focus {
      border-color: @cyan;
    }
  }
  &:focus {
    border-color: @cyan;
  }
}

.text-input() {
  width: 100%;
  height: 36px;

  .input();
}

.checkbox-input() {
  .input();

  // Fixing material-design.css stuff
  &:before {
    content: none;
  }

  + label {
    height: auto;
    padding: 0;

    &:before {
      content: none;
    }
  }
}

.file-input() {
  .input();
}

.dropdown-input() {
  width: 100%;
  height: 36px;
  min-width: min-content;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%23616161" d="M0,0 h10 l-5,5z"></path></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  .input();

  &:focus, &:active, &:hover {
     background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%230d7da5" d="M0,0 h10 l-5,5z"></path></svg>');
  }

  &.big {
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%23616161" d="M0,0 h10 l-5,5z"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 50%;
  }
}

.multiselect-input() {
  .dropdown-input();
  min-height: 5em;
  padding-left: 0;
  padding-right: 0;

  option {
    padding: 7px 9px;
  }
}

.textarea() {
  .input();
}
