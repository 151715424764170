@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";

.Tags {
  @spacing: .6rem;

  ul {
    margin: -@spacing / 2;
    margin-top: 1em - @spacing;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: @spacing / 2;

      a, button {
        @item-height: 2.286rem;

        height: @item-height;
        min-width: 0;
        min-width: unset;
        min-height: 0;
        min-height: unset;
        display: inline-block;
        padding: 0 1.15rem;
        border-radius: @item-height / 2;
        background-color: @snow-alt-1 !important;
        box-shadow: none !important;
        color: @gray-medium;
        line-height: @item-height;
        white-space: nowrap;
        vertical-align: top;

        &:enabled {
          &:focus, &:hover {
            .shadow();
            background-color: @gray !important;
            text-decoration: none;
            color: @white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
