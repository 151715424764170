@import (reference) "../../../styles/ui.less";

.SingleselectDropdown {
  &__item {
    button {
      display    : flex !important;
      align-items: center;
    }

    input[type="search"] {
      max-width  : 100%;
      max-width  : unset;
      margin-left: 0;
      margin-left: unset;
    }

    &__radio {
      input {
        &[type="radio"] {
          margin      : 0;
          margin-right: 7px;

          // Fixing material-design.css stuff
          -webkit-appearance: radio;
          position          : static;
          visibility        : visible;
          float             : none;

          &:checked {
            &:before {
              content: unset;
            }
          }
        }
      }
    }

    // Fixing material-design.css stuff
    mark {
      .mark();
    }
  }
}