@import (reference) "../../helpers.less";

@root: ~'TypedSuggestions';

.@{root} {
  .catalog-margin();
  font-family: "Roboto Mono", serif;
  font-size: 18px;
  color: rgba(255, 255, 255, .5);

  &__suggestion {
    height: 100%;
    display: inline-block;
    min-width: 220px;
    text-align: left;
  }

  &__suggestionText {
    display: inline-block;
    line-height: 1.333;
    color: rgba(255, 255, 255, .75);
    border-right: 1px solid white;
    animation: cursor-blink 1s infinite alternate-reverse;
  }

   &--dark {
    color: rgba(66, 66, 66, .5);
    & .@{root}__suggestionText {
      color: rgba(66, 66, 66, .75);
      border-right: 1px solid #424242;
      animation: dark-cursor-blink 1s infinite alternate-reverse;
    }
  }
}

@keyframes cursor-blink {
  from {
    border-color: rgba(255, 255, 255, 0);
  }

  49% {
    border-color: rgba(255, 255, 255, 0);
  }

  51% {
    border-color: rgba(255, 255, 255, 1);
  }

  to {
    border-color: rgba(255, 255, 255, 1);
  }
}

@keyframes dark-cursor-blink {
  from {
    border-color: rgba(66, 66, 66, 0);
  }

  49% {
    border-color: rgba(66, 66, 66, 0);
  }

  51% {
    border-color: rgba(66, 66, 66, 1);
  }

  to {
    border-color: rgba(66, 66, 66, 1);
  }
}