@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/colors.less";

.Paginate {
  text-align: center;

  &__list {
    @disabled-color    : fade(color(@gray-medium), 25%);
    @highlight   : fade(color(@gray-medium), 75%);
    display      : inline-block;
    padding-left : 0;
    margin       : 20px 0;
    border-radius: 4px;

    >li {
      display: inline;

      .default-style() {
        cursor         : pointer;
        position       : relative;
        float          : left;
        padding        : 6px 15px;
        margin-left    : -1px;
        color          : @gray-medium;
        text-decoration: none;
        .h2();
        font-size: 1.125rem;
        margin-bottom: 0;
        font-weight  : normal;

        &:hover {
          color  : @highlight;
        }

        &:focus {
          color  : @highlight;
        }
      }

      >a {
        .default-style();
      }

      >span {
        .default-style();
      }

      &:first-child {
        >a {
          margin-left: 0;
        }

        >span {
          margin-left: 0;
        }
      }
    }

    >.active {
      .active-item() {
        cursor : default;
        color  : @light-blue-lighten-1;
      }

      >a {
        .active-item();

        &:hover {
          .active-item();
        }

        &:focus {
          .active-item();
        }
      }

      >span {
        .active-item();

        &:hover {
          .active-item();
        }

        &:focus {
          .active-item();
        }
      }
    }

    >.disabled {
      .disabled-item() {
        color : @disabled-color;
        cursor: not-allowed;
      }

      >span {
        .disabled-item();

        &:hover {
          .disabled-item();
        }

        &:focus {
          .disabled-item();
        }
      }

      >a {
        .disabled-item();

        &:hover {
          .disabled-item();
        }

        &:focus {
          .disabled-item();
        }
      }
    }
  }
}