@import (reference) "../../../styles/spacing.less";
@import (reference) "../../../breakpoints.less";

.CMSSection {
  padding: @section-space 0;
  display: flex;
  justify-content: center;
  &__container {
    max-width: @max-width + @space + @space;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 @space;
    .mobile({
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 15px;
    });
    &--col {
      flex-direction: column;
    }
  }
}
