@import (reference) "./shadows.less";
@import (reference) "./spacing.less";

.table() {
  border-bottom: 0;

  thead {
    text-transform: none;
  }

  tr {
    border-bottom: 0;

    th {
      font-weight: bold;

      button {
        .no-shadow();
        width: auto;
        height: auto;
        min-width: 0;
        min-width: unset !important;
        display: inline-block;
        padding: 0;
        background: none;
        font: inherit;
        color: inherit;
        text-transform: inherit;
        vertical-align: unset;
      }

      &.hidden-header {
        visibility: hidden;
      }
    }

    td {
      select {
        min-width: max-content;
      }
    }
  }

  &:not(:first-child) {
    margin-top: @mb-xsmall;
  }
}
