.InfiniteScroll {
  &__showMoreButton {
    width: 100%;
    margin-top: 36px;
    background: rgba(203, 203, 203, .15);
    border: 1px solid rgba(236, 239, 241, .05);
    border-radius: 4px;
    box-shadow: none;
    color: white;

    &:hover, &:focus {
      box-shadow: none !important;
    }
  }
}
