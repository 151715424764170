@import (reference) "../styles/colors.less";
@import (reference) "../styles/typography.less";

.Products {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  background: @gradient;

  h1 {
    .display();
    margin-top: 0;
    font-weight: 400;
    color: inherit;
  }
}
