@import (reference) "../../styles/colors.less";

.StatusTag {
  border-radius: 12px;
  background-color: @fog;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.13px;
  text-align: center;
  color: @pewter;
  padding: 5px 10px;
  display: inline;
  &:not(:first-child) {
    margin-left: 5px;
  }
}