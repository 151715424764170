@import (reference) "../../styles/buttons.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.ProductDetailsSection {
  margin-bottom: 2.14rem;

  &__header {
    border-bottom: 1px solid @gray;
    margin-bottom: 1rem;
    padding-top: 1rem;

    h3 {
      margin-top: 0;
      margin-bottom: .4285rem;
    }
  }

  &__articles {
    .Article {
      display: flex;

      &__image {
        align-self: center;
        img {}
      }

      h3 {
        margin: 1em 0 0;
      }
      h3 + p {
        margin-top: 1em;

        &.Article__subHeader {
          margin-top: -14px;
          .paragraph();
          text-align: inherit !important;
        }
      }

      &__contentWithButton {
        .paragraph();
        button {
          align-self: center;
        }
      }
    }
  }

  &--rows, &--rows-large {
    .ProductDetailsSection__articles {
      flex-direction: column;
      text-align: left;

      .Article {
        margin-bottom: 2rem;

        &__image {
          width: 85px;
          height: 85px;
          flex-shrink: 0;
          align-self: center;
          margin-right: 4.5rem;

          .mobile({
            width: 42px;
            margin-right: 1.5rem;
          }, @upto-phone-landscape);

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &--rows-large {
    .ProductDetailsSection__articles {
      .Article {
        &__image {
          img {
            .rounded-corners();
            .shadow();
            width: auto;
          }
        }
      }
    }
  }

  &--columns {
    .ProductDetailsSection__articles {
      @article-width: 180px;
      @article-gap: 40px;
      display: flex;
      flex-flow: row wrap;
      text-align: center;
      margin: -@article-gap / 2;

      @supports (display: grid) {
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(auto-fit, minmax(@article-width, 1fr));
        grid-gap: @article-gap;
        margin: 0;
      }

      .Article {
        flex-basis: @article-width;
        flex-grow: 1;
        flex-flow: column;
        margin: @article-gap / 2;

        @supports (display: grid) {
          margin: 0;
        }

        &__image {
          img {
            height: 65px;
          }
        }

        &__contentWithButton {
          display: flex;
          flex-flow: column;
          flex-grow: 1;

          &__content {
            flex-grow: 1;

            p {
              text-align: left;
            }

            a {
              position: relative;
              display: inline-block;
              margin-top: 1rem;
              color: @cyan;

              &:after {
                position: absolute;
                right: -1rem;
                content: ">"
              }
            }
          }

          &__button {
            a {
              .button();
            }
          }
        }
      }
    }
  }
}
