@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/helpers.less";

.InputWithSuggestions {
  position: relative;

  &__label {
    width: 100%;
    height: 100% !important;
    padding: 0 15px;
    line-height: 36px;

    background: none !important;
    box-shadow: none !important;
    margin-bottom: 0;

    font: inherit !important;
    color: inherit;
    text-align: left;
    text-transform: inherit;

    &__arrow {
      display: inline-block;
      transform: scaleY(.5);
      font-size: 0.9em;
    }
  }

  &__items {
    .shadow();
    .rounded-corners();
    display: none;
    position: absolute;
    z-index: 999;
    background-color: white;
    padding: 10px 8px;
    animation: appear-and-slide-down 200ms ease-in-out;
    overflow: auto;

    font-size: 14px;
    color: @text-color;
    white-space: nowrap;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a, button {
          .button();
          width: 100%;
          background: none;
          box-shadow: none;
          margin-bottom: 0;
          padding-left: 11px;
          padding-right: 11px;

          font: inherit;
          font-size: 14px;
          color: inherit;
          text-align: left;
          text-transform: inherit;

          &:hover, &:focus {
            background-color: @cyan-darken-1;
            color: white !important;
          }
        }
      }
    }
  }

  &--open {
    .InputWithSuggestions__items {
      display: block;
    }
  }
}

@keyframes appear-and-slide-down {
  from {
    opacity: 0;
    clip-path: polygon(-5% -5%, 105% -5%, 105% 0%, -5% 0%);
  }

  to {
    opacity: 1;
    clip-path: polygon(-5% -5%, 105% -5%, 105% 105%, -5% 105%);
  }
}
