@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.ProductCard {
  @circle-size: 70px;
  background: rgba(255, 255, 255, .175);
  border: 1px solid rgba(236, 239, 241, .05);
  border-radius: 4px;
  color: white;
  position: relative;
  transition: background 0.3s ease-in-out;

  &--active {
    border-color: @cyan-lighten-1;
    border-width: 2px;
    background: rgba(255, 255, 255, .25);
    box-shadow: 2px 3px 14px 0 rgba(255, 255, 255, 0.25);

    .ProductCard__logo {
      img {
        @border-width: 2px;
        .circle(@circle-size + (@border-width * 2));
        border: @border-width solid transparent;
        position: relative;
        margin: -@border-width;
      }
    }
  }

  &--inactive {
    opacity: 0.45;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, .25);
  }

  &__header, &__content, &__footer {
    margin: 20px 42px;
    padding: 0;

    .mobile({
      margin-left: 14px;
      margin-right: 14px;
    }, @upto-phone-landscape);
  }

  &__header {
    display: flex;

    &__content {
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;

      h2 {
        .h1();
        font-size: 1.5rem;
        line-height: 1.5;
        margin-top: 0;
        color: white;
      }

      p {
        .small();
        margin: 0;
        color: white;
      }
    }
  }

  &__content {
    &__description {
      color: white;
      font-size: .875rem;
      .max-lines(3);
    }
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 16px;

    img {
      .circle(@circle-size);
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;
    padding: 4px 40px;
    border-top: 1px solid rgba(236, 239, 241, .4);

    .mobile({
      padding-left: 10px;
      padding-right: 10px;
    }, @upto-phone-landscape);

    .Pill {
      margin: 4px;
    }
  }
}
