@import (reference) "../../breakpoints.less";
@import (reference) "../../styles/colors.less";

.TopBar {
  padding: 0 40px;
  background: darken(@midnight, 3.5%);
  min-height: 35px;
  .mobile({
    padding: 0;
  });
  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .mobile({
      flex-wrap: wrap;
    });
  }
  &__link {
    margin: 8px 0 8px 30px;
    font-size: 14px;
    letter-spacing: 0.7px;
    font-weight: 400;
    opacity: 0.75;
    display: inline-block;
    outline: none;
    transition: .3s opacity ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    .mobile({
      margin-left: 10px;
      margin-right: 20px;
    });
  }
}
