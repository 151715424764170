@import (reference) "../../styles/colors.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../z-index.less";

.Header {
  @secondary-row-height: 28px;
  @header-bg-color: @midnight;
  @header-horizontal-padding: 20px;
  @link-color: #20b7f6;

  position: sticky;
  z-index: @z-index-menu;
  top: 0;
  background-color: @header-bg-color;
  color: white;
  font-size: 14px;

  &__flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 40px;
    min-height: 80px;
  }


  @menu-button-width: 80px;

  &__menuButton {
    display: none;
    min-width: 0;
    min-width: unset;
    height: 57.3px;
    background: none;
    box-shadow: none;
    outline: none;

    &:enabled {
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }

  @media @tablet-main-menu {
    &__signup {
      background-color: transparent !important;
    }

    &__flexContainer {
      flex-direction: column;
    }

    &__menuButton {
      display: inline-block;
      position: absolute;
      top: 14px;
      align-self: flex-end;
      right: 30px;
      background-color: transparent !important;
    }
  }

  @media @mobile-main-menu {
    &__flexContainer {
      padding: 0 20px;
    }

    &__menuButton {
      align-self: flex-end;
      right: 10px;
    }
  }
}
