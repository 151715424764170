/**
 * This file is copied over from MDB for compatibility reasons.
 */
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 0;
  &.small {
    width: 36px;
    height: 36px;
  }
  &.big {
    width: 64px;
    height: 64px;
  }
  &.active {
    animation: container-rotate 1568ms linear infinite;
  }
  &.crazy{
    animation-duration: 333ms;
  }
  .spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    .gap-patch {
      position: absolute;
      top: 0;
      left: 45%;
      width: 10%;
      height: 100%;
      overflow: hidden;
      border-color: inherit;
      .circle {
        width: 1000%;
        left: -450%;
      }
    }

    .circle-clipper {
      display: inline-block;
      position: relative;
      width: 50%;
      height: 100%;
      overflow: hidden;
      border-color: inherit;
      .circle {
        width: 200%;
        height: 100%;
        border-width: 3px;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
      }
      &.left .circle {
        left: 0;
        border-right-color: transparent !important;
        transform: rotate(129deg);
      }
      &.right .circle {
        left: -100%;
        border-left-color: transparent !important;
        transform: rotate(-129deg);
      }
    }

  }
  .spinner-blue,
  .spinner-blue-only {
    border-color: #4285f4;
  }

  .spinner-red,
  .spinner-red-only {
    border-color: #db4437;
  }

  .spinner-yellow,
  .spinner-yellow-only {
    border-color: #f4b400;
  }

  .spinner-green,
  .spinner-green-only {
    border-color: #0f9d58;
  }

  .spinner-white-only {
    border-color: #fff;
  }
}

@keyframes container-rotate {
  to {
    transform: rotate(360deg)
  }
}

.active {
  .spinner-layer {
    &.spinner-blue {
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
    }
    &.spinner-red {
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
    }
    &.spinner-yellow {
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
    }
    &.spinner-green {
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
    }
    &.spinner-blue-only,
    &.spinner-red-only,
    &.spinner-yellow-only,
    &.spinner-green-only,
    &.spinner-primary-color-only {
      opacity: 1;
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
    }
  }
  .circle-clipper.left .circle {
    animation: left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  }
  .circle-clipper.right .circle {
    animation: right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}

@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}

@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
