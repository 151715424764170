@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/helpers.less";

.Dropdown {
  @dropdown-vertical-padding: 10px;
  @dropdown-horizontal-padding: 8px;
  height: 40px;
  padding: 0;
  display: inline-block;
  vertical-align: top;

  position: relative;

  &__label {
    .button();
    display: flex;
    align-items: center;
    min-width: 0 !important;
    min-width: unset !important;
    width: 100%;
    height: 100% !important;
    padding: 0 15px;
    line-height: 36px;
    white-space: pre;

    background: none !important;
    box-shadow: none !important;
    outline: none;
    margin-bottom: 0;

    font: inherit !important;
    color: inherit !important;
    text-align: left !important;
    text-transform: inherit;

    &__label {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__arrow {
      display: inline-block;
      transform: scaleY(.5);
      font-size: 0.9em;
    }

    // Fixing material-design.css stuff
    &:hover, &:focus {
      color: inherit;
    }
  }

  &__content {
    .shadow();
    .rounded-corners();
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 999;
    background-color: white;
    animation: appear-and-slide-down 200ms ease-in-out;
    overflow: hidden;

    font-size: 14px;
    color: @text-color;
    white-space: nowrap;

    &[style*="top: unset"] {
      animation-name: appear-and-slide-up;
    }
  }

  &__items {
    margin: 0;
    padding: @dropdown-vertical-padding @dropdown-horizontal-padding;
    overflow: auto;
    list-style-type: none;

    li {
      a, button {
        .button();
        width: 100%;
        background: none;
        box-shadow: none;
        margin-bottom: 0;
        padding-left: 11px;
        padding-right: 11px;
        overflow: hidden;

        font: inherit;
        font-size: 14px;
        color: inherit;
        text-align: left;
        text-transform: inherit;
        text-overflow: ellipsis;

        &:enabled {
          &:hover, &:focus {
            background-color: @cyan-darken-1;
            color: white !important;
          }
        }

        &:disabled {
          background: none !important;
          color: lightgray;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-shrink: 0;
    background-color: @divider-color;
    padding: 1px 1px 0;

    a, button {
      .button();
      min-width: 0;
      min-width: unset;
      background: white;
      box-shadow: none;
      margin: 0 .5px;
      padding-left: 11px;
      padding-right: 11px;
      overflow: hidden;
      flex-grow: 1;

      font: inherit;
      font-size: 14px;
      color: @cyan-darken-1;
      text-transform: inherit;
      text-overflow: ellipsis;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:enabled {
        &:hover, &:focus {
          background-color: @cyan-darken-1;
          color: white !important;
        }
      }

      &:disabled {
        background: none !important;
        color: lightgray;
      }
    }
  }

  &--open {
    .Dropdown__content {
      display: flex;
    }
  }
}

@keyframes appear-and-slide-down {
  from {
    opacity: 0;
    clip-path: polygon(-5% -5%, 105% -5%, 105% 0%, -5% 0%);
  }

  to {
    opacity: 1;
    clip-path: polygon(-5% -5%, 105% -5%, 105% 105%, -5% 105%);
  }
}

@keyframes appear-and-slide-up {
  from {
    opacity: 0;
    clip-path: polygon(-5% 105%, 105% 105%, 105% 105%, -5% 105%);
  }

  to {
    opacity: 1;
    clip-path: polygon(-5% -5%, 105% -5%, 105% 105%, -5% 105%)
  }
}
