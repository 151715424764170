@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.AttributeCard {
  @circle-size: 80px;
  @margin-width: 16px;
  background: @white;
  border: 1px solid @white;
  border-radius: 4px;
  color: @gray-dark;
  .shadow();

  &--active {
    .AttributeCard__footer {
      &__content {
        background: fade(color(@pale-grey), 50%);
        padding: @margin-width;
        &__body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          &__info {
            padding: .3125rem .75rem 2.125rem;
            table > tbody tr td {
              padding-left: .75rem;
            }
            &__title {
              margin-bottom: 0;
              border-bottom: 1px solid fade(color(@pewter-light, 25%), 50%);
              padding-bottom: .5rem;
              & + table {
                margin-top: .75rem;
              }
            }
          }
          &__table {
            background: @white;
            text-align: center;
            padding: .5rem 1.8125rem .6875rem;
            box-shadow: 1px 4px 3px 0 rgba(155, 155, 155, 0.35);
            .mobile({
              margin: .375rem auto;
            }, @upto-phone-landscape);
            &__title {
              font-weight: bold;
              margin-bottom: .75rem;
              color: @gray-medium;
            }
            table {
              border-spacing: 0;
              border-collapse: collapse;
              &:not(:first-child) {
                margin-top: 0;
              }
              tbody tr:first-child td {
                padding-top: .5rem;
              }
              th, td {
                padding: .3rem .5rem;
              }
              td {
                color: @gray-medium;
              }
              thead {
                tr {
                  border-top: 1px solid @gray;
                  border-bottom: 1px solid @gray;
                  color: @gray;
                  th {
                    font-weight: inherit;
                    padding-top: 0;
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__header, &__content, &__footer {
    margin: 0 @margin-width;
    padding: 0;

    .mobile({
      margin-left: .875rem;
      margin-right: .875rem;
    }, @upto-phone-landscape);
  }

  &__header {
    display: flex;
    margin-top: 1.25rem;
    .mobile({
      flex-direction: column;
      text-align: center;
      align-items: center;
    }, @upto-phone-landscape);
    &__content {
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;
      align-self: flex-start;
      .mobile({
        align-self: center;
        margin: .375rem;
      }, @upto-phone-landscape);
      &__title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .mobile({
          justify-content: center;
        }, @upto-phone-landscape);
        &__attribute-name {
          .h1();
          font-size: 1.5rem;
          line-height: 1.5;
          display: inline-flex;
          color: @gray-dark;
          margin: 0 .375rem 0 0;
        }
        &__product-name {
          .h2();
          font-size: 1.125rem;
          line-height: 1.125;
          font-weight: normal;
          margin-bottom: 0;
          color: @gray-medium;
          a:hover {
            color: @light-blue-lighten-1;
          }
        }
      }
      h2 {
        color: @gray-dark;
        font-weight: normal;
        margin: 0;
      }
    }
    &__info {
      &__price {
        padding-top: .4rem;
        margin-bottom: 0;
        text-align: right;
        letter-spacing: 4px;
        &__leftover {
          color: fade(@gray, 40%);
        }
        .mobile({
          text-align: center;
        }, @upto-phone-landscape);
      }
    }
  }

  &__content {
    &__description {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-left: @circle-size;
      // margin: 0;
      flex-wrap: wrap;
      justify-content: center;
      .mobile({
        flex-direction: column;
      }, @upto-phone-landscape);
      &__empty {
        margin: 1rem 1rem 0;
        a {
          color: @light-blue-lighten-1;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 1rem;
    .mobile({
      margin-right: 0;
    }, @upto-phone-landscape);
    img {
      .circle(@circle-size);
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 1.6875rem 0 0;
    border-top: 1px solid @snow-alt-2;
    align-items: center;

    &__trigger {
      padding: 11px @margin-width;
      color: @light-blue-lighten-1;
      font-weight: bold;
      font-size: 18px;
      a {
        display: inline-flex;
      }
      img {
        margin: 0 4px;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      width: 100%;
    }
  }
}
