@import (reference) "../../styles/buttons.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../z-index.less";

.Authentication {
  @link-color: #20b7f6;
  @button-color: @cyan;
  @avatarSize: 37px;
  @headerHeight: 80px;
  display: inline-flex;

  a {
    .button();
    width: 50%;
    padding: 8px 16px;
    flex-grow: 1;
    min-width: 65px;
    color: white !important;
    font-weight: 400;
    white-space: nowrap;
    transition: .3s background-color ease-in-out;

    &.Authentication__request-data {
      background-color: @button-color;
      min-width: min-content;
      margin-left: 20px;
      font-weight: 500 !important;
      &:hover, &:focus {
        text-decoration: none !important;
        background-color: darken(@button-color, 10%);
      }
    }
  }

  &__userInfo {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: normal;
    text-transform: none;

    .Dropdown {
      &__content {
        top: calc(100% + ((@headerHeight - @avatarSize)/2) - 8px) !important;
        background-color: @white !important;
        border-radius: 0 !important;
        font-size: 1em !important;
        .layered-shadow-darkened() !important;
      }
      &__label {
        display: inline-block;
        padding: 0;
        margin-left: 20px;

        .UserAvatar {
          background-color: @pewter;
          transition: .3s background-color ease-in-out;
          &:hover, &:focus {
            background-color: darken(@pewter, 10%);
          }
        }
      }

      &__items {
        @item-link-color: @cyan;
        padding: 10px 15px!important;
        li {
          a, button {
            font-size: 1em !important;
            letter-spacing: 1.17px !important;
            transition-property: none;
            min-width: 160px !important;
            color: @item-link-color !important;
            padding: 10px !important;
            margin: 0 !important;
            b { font-weight: 500 !important; }

            &:hover, &:focus {
              background-color: transparent;
              color: darken(@item-link-color, 10%) !important;
              box-shadow: none;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media @tablet-main-menu {
    order: -1;
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 1em;
    padding: 0;

    a {
      display: block;
      width: auto;
      margin: 0;

      &:hover, &:focus, &.active {
        color: @link-color !important;
      }

      &.Authentication__request-data {
        color: @white !important;
        padding: 15px 0;
        width: calc(100% - 80px);
        margin: 0 40px;
        *:hover, &:focus, &.active {
          color: @white !important;
        }
      }

    }

    &__userInfo {
      .Dropdown {
        &__label {
          pointer-events: none;
          margin: 0;

          &__arrow {
            display: none;
          }
        }
      }
    }
  }
}
