@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/buttons.less";
@import (reference) "../../breakpoints.less";

.ProductDetailsAnonymous {
  flex-grow: 1;
  padding: @space * 2 @space;
  color: @white;
  background-image: linear-gradient(to left, @cyan-lighten-1 0%, @cyan 100%);
  section {
    margin: 0 auto;
    max-width: 510px;
  }
  &__close {
    position: absolute;
    top: 44px;
    left: 26px;
    min-width: 0;
    min-width: unset;
    width: 32px;
    height: 32px;
    padding: 0;
    background: none;
    box-shadow: none;
    background-color: transparent;
    line-height: 1;
    svg g {
      stroke: @white;
    }
    .mobile({
      top: 25px;
      left: 9px;
    }, @upto-phone-landscape);

    &:enabled {
      &:hover, &:focus {
        background: none;
        box-shadow: none;
        border: 0;
        outline: none;

        img, svg {
          opacity: .75;
        }
      }
    }
  }
  &__title {
    font-family: Lato;
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: -0.3px;
    margin: 0 0 @mb-xxsmall;
  }
  &__body {
    color: inherit;
    margin: 0 0 @mb-large;
    span {
      font-weight: normal;
    }
  }
  &__privacy-message {
    text-align: center;
    small {
      font-weight: normal;
      color: @white;
    }
    a {
      color: @baby;
      font-size: inherit !important;
      font-weight: 400 !important;
    }
  }
  &__icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 70px 0 0;
    figure {
      text-align: center;
      margin: 0;
      figcaption {
        color: @baby;
        font-size: @small-size;
      }
    }
  }
  form {
    label {
      display: block !important;
      font-size: @paragraph-size;
      margin: 0.3125rem 0 0!important;
    }
    input,
    textarea {
      min-width: 0;
      min-width: unset;
      max-width: unset !important;
      margin-bottom: @mb-xxxsmall;
      transition: 0.3s all ease-in-out;
    }
    textarea {
      width: 100%;
      min-height: 10em;
    }
    button[type="submit"] {
      display: block !important;
      margin: 0 auto;
      margin-top: @mb-xxsmall;
      width: 100%;
      .button-as-cta();
      background-color: @navy !important;
    }
  }
}