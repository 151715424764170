@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.ProductHeader {
  @vertical-padding: 20px;
  @horizontal-padding: 54px;

  padding: @vertical-padding @horizontal-padding;
  border-bottom: 1px solid @pewter-light;
  flex-shrink: 0;

  .mobile({
    padding-left: 14px;
    padding-right: 14px;
  }, @upto-phone-landscape);

  &__controls {
    flex-grow: 1;
    margin: auto !important;
  }

  h2 {
    .h1();
    font-size: 1.5rem;
    margin: 0;
  }

  &__data_source {
    display: flex;
    align-items: center;

    &__name {
      color: @gray-medium;
      margin-right: 1.25em;
    }

    &__logo {
      @circle-size: 52px;

      img {
        .circle(@circle-size);
        display: block;
      }
    }
  }
}
