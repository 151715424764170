@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.Filters {
  margin: 0 2.75rem 2.75rem 3.9375rem;

  &__title {
    .h1();
    font-size: 1.5rem;
    color : @gray-medium;
    margin: 0;

    .mobile({
      text-align: center;
    }, @upto-phone-landscape);
  }

  &__section {
    padding      : 1.5rem 0;
    border-bottom: 1px solid fade(@pewter-light, 25%);

    .mobile({
      text-align: center;
    }, @upto-phone-landscape);

    &__title {
      .h1();
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
      color : @gray-medium;
    }
  }
}