@import (reference) "../../../styles/ui.less";

.MultiselectDropdown {
  &__item {
    button {
      display: flex !important;
      align-items: center;
    }

    input[type="search"] {
      max-width: 100%;
      max-width: unset;
      margin-left: 0;
      margin-left: unset;
    }

    &__checkbox {
      input {
        &[type="checkbox"] {
          margin: 0;
          margin-right: 7px;

          // Fixing material-design.css stuff
          -webkit-appearance: checkbox;
          position: static;
          visibility: visible;
          float: none;

          &:checked {
            &:before {
              content: unset;
            }
          }
        }
      }
    }

    // Fixing material-design.css stuff
    mark {
      .mark();
    }
  }
}
