@import (reference) "../../styles/typography.less";

.Pill {
  .small();
  min-width: 80px;
  max-width: 100%;
  white-space: nowrap;
  height: 1.286rem;
  display: inline-flex;
  margin-right: 10px;
  padding: .07rem .5rem;
  border: 1px solid rgba(236, 239, 241, .4);
  border-radius: 4px;
  text-align: center;
  color: #fff;

  &__label {
    margin-right: .2rem;
  }

  &__content {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
}
