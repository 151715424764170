@import (reference) "../../helpers.less";

.Timeline {
  @left: 8em;
  position: relative;
  &__content {
    position: relative;
    max-width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    &:before {
      background-color: rgba(222, 226, 234, .75);
      content: '';
      margin-left: -1px;
      position: absolute;
      top: 8px;
      left: @left;
      width: 2px;
      height: calc(100% - 65px);
    }
  }
}
