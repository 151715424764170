@import (reference) "../../styles/buttons.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";

.UploadFile {
  &__options {
    .layered-shadow();
    background: @white;
    padding: @small-space;
    label {
      .h4();
    }
    select, input, .MultiselectDropdown {
      display: block;
      flex-grow: 1;
      margin-bottom: @mb-xxxsmall;
      max-width: 100%;
    }
    input[type="submit"] {
      width: 100%;
      .button();
    }
  }
  &__selected-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 @mb-xxxsmall;
    flex-wrap: wrap;
    &__name {
      font-size: @paragraph-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: @pewter;
      word-break: break-word;
    }
    &__close {
      min-width: 0;
      min-width: unset;
      padding: 0;
      background: none;
      box-shadow: none;
      background-color: @gray-medium;
      opacity: 0.5;
      line-height: 1;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      min-height: unset;
      svg {
        width: 14px;
        height: 14px;
        padding: 3px;
        g {
          stroke: @white;
          stroke-width: 6px;
        }
      }
      &:enabled {
        &:hover, &:focus {
          background: @gray-medium;
          box-shadow: none;
          border: 0;
          outline: none;
          opacity: .75;
        }
      }
    }
  }
  .MultiselectDropdown {
    .Dropdown__label {
      border: 1px solid @gray;
      &:focus {
        border-color: @cyan;
      }
      &:hover {
        border-color: #afb9cc;
        .Dropdown__label__arrow {
          color: @cyan;
        }
      }
      .Dropdown__label__label {
        color: @gray-medium;
        font-weight: 400;
      }
    }
  }
}
