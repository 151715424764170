@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.AttributeStat {
  padding: 0 1.40625rem;
  .mobile({
    padding: .625rem 1.40625rem;
  }, @upto-phone-landscape);
  &__title {
    text-align: center;
  }
  &__value {
    text-align: center;
    .h1();
    font-size: 1.5rem;
    margin: 0;
  }
}
