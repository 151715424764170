@import (reference) "./colors.less";

.global() {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: @grey-darken-3;
}

// LatoRegular comes from Manta into stingray (custom fonts)
// Need Lato as a fall back here also since that is the font in marlin
// and marlin references this file for styles
.display() {
  font-family: LatoRegular, Lato, system-ui, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 1em;
}

.h1() {
  font-family: LatoRegular, Lato, system-ui, Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: @grey-darken-3;
  margin-top: 0;
  margin-bottom: 1em;
}

.h2() {
  font: inherit;
  font-weight: 800;
  font-size: 18px;
  color: @grey-darken-2;
  margin-bottom: 1em;
}

.h3() {
  font: inherit;
  font-weight: 300;
  font-size: 18px;
  color: @grey-darken-3;
  margin-bottom: 1em;
}

.h4() {
  font: inherit;
  font-weight: 800;
  font-size: 14px;
  color: @grey-darken-3;
  margin-bottom: 1em;
}

.h5() {
  font: inherit;
  font-weight: 400;
  font-size: 14px;
  color: @grey-darken-2;
  margin-bottom: 1em;
}

.h6() {
  font: inherit;
  font-weight: 600;
  font-size: 11px;
  color: @grey;
  margin-bottom: 1em;
}

.title() {
  font: inherit;
  font-weight: 600;
  font-size: 14px;
  color: @grey-darken-3;
  margin-bottom: 1em;
}

.paragraph() {
  .global();
  color: inherit;
  line-height: inherit;
  letter-spacing: -.2px;

  // Fixing material-design.css stuff
  margin-top: 1em;
  margin-bottom: 1em;
}

.label() {
  .paragraph();
  font-weight: 500;
  margin-bottom: 0;
}

.link() {
  color: @cyan-darken-1;

  &:hover, &:focus {
    text-decoration: underline;
  }
}
