@import (reference) "../styles/colors.less";
@import (reference) "../styles/spacing.less";
@import (reference) "../styles/typography.less";

.Error404 {
  margin-bottom: @section-space;
  text-align: center;
  &.CMSSection {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__code {
    font-size: 6rem;
    color: @fog;
    margin-bottom: @small-space;
  }
  &__title {
    font-size: @display-size;
    font-weight: 400;
    color: @navy;
    line-height: 1.16;
    letter-spacing: -0.5px;
  }
  &__content {
    color: @gray-dark;
    margin-bottom: @small-space * 2;
  }
  &__cta.button {
    font-size: 1rem;
    background-color: @cyan;
    min-width: 151px;
    padding: @small-space;
    font-weight: 500;
    letter-spacing: 1.14px;
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: darken(@cyan, 10%);
    }
  }
}
