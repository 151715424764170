@import (reference) "./colors.less";
@import (reference) "./helpers.less";
@import (reference) "./spacing.less";

.table() {
  border-bottom: 0;

  thead {
    text-transform: none;
  }

  tr {
    border-bottom: 0;

    th {
      font-weight: bold;

      button {
        .no-shadow();
        width: auto;
        height: auto;
        min-width: 0;
        min-width: unset !important;
        display: inline-block;
        padding: 0;
        background: none;
        font: inherit;
        color: inherit;
        text-transform: inherit;
        vertical-align: unset;
      }

      &.hidden-header {
        visibility: hidden;
      }
    }

    td {
      select {
        min-width: max-content;
      }
    }
  }

  &:not(:first-child) {
    margin-top: @margin;
  }
}

.button() {
  .rounded-corners-small();
  .shadow-small();
  height: auto;
  min-width: 135px;
  min-height: 36px;
  padding: 8px 15px;
  display: inline-block;
  border: none;
  background-color: @mdb-color-lighten-2;
  border: 0;
  border-radius: 2px;
  vertical-align: top;
  color: white;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  line-height: 20px; // 36px height - 8px padding - 8px padding
  outline: none;

  &:disabled {
    // !important needed because of color classes from Material Design
    background-color: @grey !important;
  }

  &:enabled {
    cursor: pointer;

    &:hover, &:focus {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.03),
          0 6.3px 7px -1px rgba(0, 0, 0, 0.03), 0 3.4px 6.9px 0 rgba(0, 0, 0, 0.04),
          0 1.9px 3.9px 0 rgba(0, 0, 0, 0.05), 0 1px 2.1px 0 rgba(0, 0, 0, 0.05),
          0 0.4px 0.9px 0 rgba(0, 0, 0, 0.07);
      text-decoration: none !important;
    }

    &.mdb {
      background-color: @mdb-color-darken-2 !important;
    }

    &.cyan {
      background-color: @cyan-darken-1 !important;
    }

    &.light-blue {
      background-color: @light-blue !important;
    }

    &.teal {
      background-color: @teal !important;
    }

    &.red {
      background-color: @red !important;
    }

    &.border-only {
      background-color: transparent !important;
      border: 1px solid @cyan-darken-1;
      box-shadow: none;
      color: @cyan-darken-1;

      &:hover, &:focus {
        border-color: @cyan;
        color: @cyan;
      }
    }
  }

  // A button that looks like a link, but will stay in line with other buttons
  &.link-button {
    .link();
    background-color: transparent;
    box-shadow: none;
    font: inherit;

    &:enabled {
      &:hover, &:focus {
        box-shadow: none;
        text-decoration: underline !important; // While we inherit from .link(), .button() removes the underline
      }
    }

    svg {
      margin-right: 0.5rem;
      position: relative;
      top: 1px;
    }
  }

  // A link button looking just like <a>
  &.link-button-inline {
    .link-button();
    min-width: 0;
    min-width: unset;
    min-height: 0;
    min-height: unset;
    padding: 0;
    line-height: inherit;
  }

  &.icon-button {
    .no-shadow();
    min-width: 36px;
    padding: 0;
    background: none;

    svg {
      *[stroke]:not([stroke="none"]) {
        stroke: @grey;
      }

      *[fill]:not([fill="none"]) {
        fill: @grey;
      }
    }

    &:hover, &:focus {
      svg {
        *[stroke]:not([stroke="none"]) {
          stroke: @grey-darken-2;
        }

        *[fill]:not([fill="none"]) {
          fill: @grey-darken-2;
        }
      }
    }
  }

  &.round-button {
    height: 28px;
    min-width: 0;
    min-width: unset;
    min-height: 28px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;

      *[stroke]:not([stroke="none"]) {
        stroke: white;
      }

      *[fill]:not([fill="none"]) {
        fill: white;
      }
    }
  }

  &.circle-button {
    .round-button();
    width: 28px;
    padding: 0;
  }

  &.huge-add-button {
    .dashed-border();
    flex-grow: 1;
    width: 100%;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;

    &:hover, &:focus {
      box-shadow: none;
    }

    div {
      margin-top: .25em;
      line-height: 1.25;
    }
  }
}

.input() {
  max-width: 260px;
  background-color: @white;
  padding: 5px 9px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font: inherit;
  font-weight: 400;
  font-size: 1em;
  outline: none;

  &::placeholder {
    color: rgba(@mdb-color-lighten-2, 0.5);
  }

  &.big {
    max-width: none;
    max-width: unset;
    background-color: transparent;
    padding: 0;
    padding-bottom: 10px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid @mdb-color-lighten-3;
    font-size: 18px;

    &:focus {
      border-bottom-width: 2px;
    }
  }

  &:focus {
    border-color: @cyan-darken-1;
  }
}

.text-input() {
  width: 100%;
  height: 36px;

  .input();
}

.checkbox-input() {
  .input();

  // Fixing material-design.css stuff
  &:before {
    content: none;
  }

  + label {
    height: auto;
    padding: 0;

    &:before {
      content: none;
    }
  }
}

.file-input() {
  .input();
}

.dropdown-input() {
  width: 100%;
  height: 36px;
  min-width: min-content;

  .input();

  &.big {
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="gray" d="M0,0 h10 l-5,5z"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 50%;
  }
}

.multiselect-input() {
  .dropdown-input();
  min-height: 5em;
  padding-left: 0;
  padding-right: 0;

  option {
    padding: 7px 9px;
  }
}

.textarea() {
  .input();
}

.mark() {
  background-color: @custom-highlight-yellow;

  // Fixing material-design.css stuff
  padding: 0;
}

.small() {
  font-size: 11px;
}

.w-100 {
  width: 100%;
}

.d--block {
  display: block;
}

.d--inline-block {
  display: inline-block;
}
