@import (reference) "../../helpers.less";

.ProductCards {
  .catalog-margin();
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:not(:first-child) {
        margin-top: 36px;
      }

      a {
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
