@import (reference) "../../styles/colors.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../styles/typography.less";

.Menu {
  @link-color: @cyan-lighten-1;
  margin-left: auto;

  &__list {
    margin: 0;
    padding: 0;

    &__item {
      display: inline-block;
      line-height: 20px;
      position: relative;

      &--mobileOnly {
        display: none;
      }

      &--isOpen {
        .Menu__list {
          top: calc(100% - 8px) !important;
          opacity: 1 !important;
        }
      }

      &--beta {
        a, button {
          &::after {
            position: absolute;
            content: "Beta";
            background: @cyan-lighten-1;
            padding: 0 6px;
            color: white;
            border-radius: 2px;
            letter-spacing: 1px;
            bottom: -4px;
            right: 6px;
            font-size: 10px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            .mobile({
              bottom: unset;
              right: unset;
              margin-left: 8px;
            }, @upto-tablet); // Used to be 800px
          }
        }
      }

      a, button {
        position: relative;
        display: inline-block;
        padding: 32px 20px 28px;
        background: none;
        box-shadow: none;
        outline: none;
        transition: .3s color ease-in-out;
        font: inherit;
        text-transform: inherit;
        cursor: pointer;
        font-weight: 500;

        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 3px;
          bottom: 0;
          left: 0;
          right: 0;
          transition: .3s background-color ease-in-out;
        }

        &:hover {
          text-decoration: none;
          box-shadow: none !important;
          color: darken(@fog, 15%);
        }
        &:focus, &.active {
          text-decoration: none;
          box-shadow: none !important;
          color: @link-color;

          &:hover {
            color: @link-color !important;
          }

          &::before {
            background-color: @link-color;
          }
        }




      }

      &__arrow {
        .arrow();
        vertical-align: top;
      }
    }

    // Nested list
    & & {
      top: -999px;
      position: absolute;
      right: 0;
      left: unset;
      bottom: unset;
      background-color: @white;
      padding: 10px 15px;
      display: block;
      opacity: 0;
      transition: .3s opacity ease-in-out;
      .layered-shadow-darkened() !important;

      .Menu__list__item {
        display: block;

        a, button {
          width: 100%;
          padding: 10px !important;
          min-width: 150px;
          letter-spacing: 1.17px !important;
          font-weight: 500 !important;
          margin: 0 !important;
          color: @cyan !important;
          transition: .3 all ease-in-out;
          &:hover, &:focus {
            color: darken(@cyan, 10%) !important;
          }
          &::before {
            content: none;
            content: unset;
          }
        }

        &.barometer_for_smes a {
          min-width: 250px;
        }
      }
    }
  }

  @media @tablet-main-menu {
    width: 100%;

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        display: block;

        a, button {
          width: 100%;
          padding: 15px 20px;
          letter-spacing: 0 !important;

          &:hover, &:focus, &.active {
            color: @link-color !important;

            &:before {
              content: none;
            }
          }
          &.header {
            display: none;
          }
          b {
            font-weight: 500 !important;
          }
        }

        &.about {
          order: 8;
        }

        &--mobileOnly {
          display: block;
          order: 9;
        }
      }

      // Nested list
      & & {
        position: static;
        top: 0;
        padding: 0 !important;
        background: transparent !important;
        opacity: 1 !important;
        box-shadow: none !important;
        .Menu__list__item {
          a, button {
            color: @white !important;
            letter-spacing: 0 !important;
            padding: 15px 20px !important;
          }
        }
      }
    }

    &--closed {
      transform: scaleY(0);
      animation-name: squash;
    }
  }
}
