@import (reference) "../../styles/typography.less";

.SecurityPill {
  .small();
  @teal: #1de9b6;
  min-width: 48px;
  max-width: 100%;
  white-space: nowrap;
  display: inline-flex;
  margin: 0 3px;
  padding: 5px 14px;
  border: 1px solid rgba(236, 239, 241, .5);
  border-radius: 14px;
  text-align: center;
  text-transform: uppercase;

  &--default {
    border: 1px solid #93A0BA;
    color: #93A0BA;
  }

  &--fcra {
    border: 1px solid @teal;
    color: @teal;
  }

  &--pii {
    border: 1px solid @cyan-lighten-1;
    color: @cyan-lighten-1;
  }

  &__content {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
}
