@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/typography.less";
@import (reference) "../../../helpers.less";

.QualityFilter {
  &__range {
    &:not(:first-child) {
      margin: 1rem 0 0 0;
    }

    &__title {
      margin: 0 0 1rem 0;

      span {
        .h2();
        font-size: 1.125rem;
        font-weight: normal;
      }

      img {
        margin-left: .5rem;
        width      : 1rem;
        height     : 1rem;
      }
    }

    &__values {
      font-size: .875rem;
      color    : @gray-medium;
      margin   : .75rem 0 1rem;
    }

    &__slider {
      .slider();
      padding: 0 .5rem;
    }
  }
}