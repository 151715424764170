@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../breakpoints.less";

.Footer {
  padding: @section-space @space;
  background: @midnight;
  color: @pewter;
  display: block;

  &__wrapper {
    width: 100%;
    max-width: @max-width;
    text-align: center;
    margin: 0 auto;
  }
  &__foot-note {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mobile({
      flex-direction: column;
    }, @upto-phone-landscape);
    &__links {
      font-size: 14px;
      text-align: left;
      .mobile({
        margin: 0 auto;
        align-items: center;
        text-align: center;
      }, @upto-phone-landscape);
      &__text,
      &__link {
        display: inline-block;
        color: @fog;
        font-size: 12px;
        line-height: 1.8;
        font-weight: 400;
        opacity: 0.8;
        transition: 0.3s all ease-in-out;
        &:not(:last-child) {
          &::after {
            content: "·";
            font-weight: 900;
            margin: 0 10px;
            color: @pewter;
          }
        }
        &:hover {
          color: darken(@fog, 10%);
        }
      }
      &__link {
        margin-bottom: 10px;
        .mobile({
          display: block;
          &::after {
            content: none !important;
          }
        }, @upto-phone-landscape);
      }
      &__text {
        .mobile({
          display: block;
          margin-bottom: 25px;
          &::after {
            content: none !important;
            margin: 0 !important;
          }
        }, @upto-phone-landscape);
      }
    }
    img {
      .mobile({margin: 15px auto 0;}, @upto-phone-landscape);
    }
  }
}
