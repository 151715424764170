@import (reference) "../../breakpoints.less";
@import (reference) "../../styles/colors.less";

@logo-width: 120px;

.HeaderLogo {
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    img {
      width: @logo-width;
      height: auto;
    }
  }

  &__label {
    font-weight: 400;
    letter-spacing: 0.75px;
    color: @cyan;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    display: inline-block;
    text-transform: uppercase;
  }

  @media @tablet-main-menu {
    align-self: flex-start;
  }

  @media @mobile-main-menu {
    align-self: flex-start;
  }
}
