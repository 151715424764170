@import (reference) "../../helpers.less";

.AttributeCards {
  .attribute-catalog-margin();

  &__list {
    margin    : 0;
    padding   : 0;
    list-style: none;

    &__item {
      &:not(:first-child) {
        margin-top: 36px;
      }

      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
