@import (reference) "./colors.less";
@import (reference) "./ui.less";
@import (reference) "./spacing.less";
@import (reference) "./typography.less";

.rounded-corners() {
  border-radius: 4px;
}

.rounded-corners-top() {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-corners-bottom() {
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-corners-left() {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-corners-right() {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-corners-small() {
  border-radius: 2px;
}

.rounded-corners-small-top() {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.rounded-corners-small-bottom() {
  border-radius: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.rounded-corners-small-left() {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.rounded-corners-small-right() {
  border-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.circle(@size: auto) {
  width: @size;
  height: @size;
  border-radius: 50%;
  overflow: hidden;
}

.shadow() {
  box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.3);
}

.shadow-hover() {
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.3);
}

.shadow-small() {
  box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
}

.shadow-small-hover() {
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.inset-shadow() {
  box-shadow: inset 0 2px 9px -2px rgba(0, 0, 0, 0.3);
}

.no-shadow() {
  box-shadow: none !important;

  &:hover, &:focus {
    box-shadow: none !important;
  }
}

.max-lines(@numberOfLines) {
  // Since global line-height is 1.5, maximum height should be number of lines * 1.5
  max-height: @numberOfLines * 1.5em;
  overflow: hidden;

  @supports (display: -webkit-box) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @numberOfLines;
  }
}

.dashed-border() {
  padding: 20px;
  border: 1px dashed @mdb-color-lighten-3;
  border-radius: 4px;
  color: @mdb-color-lighten-3;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
}

.border-divider(@position: left) {
  border-@{position}: 1px solid @divider-color;
}

.center-content() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flat-centered-button() {
  .center-content();
  background: none;
  box-shadow: none;
  color: @grey-darken-3;

  &:hover {
    box-shadow: none !important;
  }
}

.empty-message-icon() {
  filter: grayscale(100%);
  display: block;
  margin: 0 auto 2em;
}

.blue-table() {
  border-color: transparent;

  th {
    button {
      width: 100%;
      min-height: 0;
      height: 1.5em;
      background-color: transparent !important;
      font-size: inherit;
      padding: 0;
    }
  }

  &>thead {
    background-color: #14355c;
    border-radius: 4px;
    color: @white;
    text-align: center;
    font-family: Roboto, sans-serif;

    tr th {
      font-size: 14px;
      text-transform: capitalize;
      padding: 0.3rem;

      &:first-child {
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
      }
    }

    &+tbody {
      box-shadow: 0 2px 9px -3px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      border-color: transparent;

      tr {
        color: #616161;

        th {
          font-size: 14px;
        }
      }
    }
  }

  tr {
    border-color: transparent;

    &:not(:first-child) td {
      border-top: 1px solid #929fba;
    }

    th {
      border-color: transparent;
      border-bottom: 1px solid #14355c;
      text-align: center;
      font-weight: normal;
    }

    td {
      /*(padding: .3rem 2rem;*/
      text-align: center;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 100px;
      max-width: 100px;

      &:not(:last-child) {
        border-right: 1px solid #929fba;
      }
    }
  }
}

.apply-new-styles() {
  .global();
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *, *:before, *:after {
    box-sizing: border-box;
  }

  h1 {
    .h1();
  }

  h2 {
    .h2();
  }

  h3 {
    .h3();
  }

  p {
    .paragraph();
  }

  a {
    .link();
  }

  b, strong {
    font: inherit;
    font-weight: bold;
  }

  table {
    .table();
  }

  form {
    font: inherit;
  }

  label {
    .label();
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="search"],
    &[type="tel"],
    &[type="number"] {
      .text-input();
    }

    &[type="search"] {
      display: block;
      margin-left: auto;
      padding-right: 24px; // Space for the icon
      background-color: @blue-grey-lighten-5;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%23424242%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: calc(100% - 9px) 50%;
      border: 1px solid @blue-grey-lighten-5;
      -webkit-appearance: none;

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }

      &.big {
        margin-left: 0;
        margin-left: unset;
        background-position: 100% 5px;
      }

      &.light {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%23fff%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E');
        color: white;
      }
    }

    &[type="checkbox"] {
      .checkbox-input();
    }

    &[type="file"] {
      .file-input();
      // Fixing material-design.css stuff
      line-height: unset;
    }

    &[type="checkbox"],
    &[type="radio"] {
      // Fixing material-design.css stuff
      margin: 0;
      position: static;
      left: 0;
      visibility: visible;
      margin: unset;
      position: unset;
      left: unset;
      visibility: unset;
      -webkit-appearance: checkbox;
    }
  }

  textarea {
    .textarea();
  }

  select {
    .dropdown-input();

    &[multiple] {
      .multiselect-input();
    }
  }

  button, a.button {
    .button();
  }

  mark {
    .mark();
  }

  small {
    .small();
  }

  .visually-hidden {
    position: absolute;
    top: -1px;
    left: -1px;
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: top left;
    user-select: none;
  }
}

.screen-reader-only() {
  position: absolute !important;
  height  : 1px;
  width   : 1px;
  overflow: hidden;
  clip    : rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
