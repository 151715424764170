@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/spacing.less";
@import (reference) "../../../styles/typography.less";
@import (reference) "../../../breakpoints.less";

.APIDocs {
  &.CMSSection {
    flex-wrap: wrap;
  }
  margin-bottom: @section-space;
  > .CMSSection__container {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__nav {
    min-width: 28%;
    max-width: 28%;
    padding-right: @space;
    position: sticky;
    top: @section-space + 80px; // Padding + top navbar
    h3 {
      margin-bottom: @mb-xxsmall;
    }
    .mobile({
      max-width: 100%;
      margin-right: 0;
      position: unset;
      top: unset;
    });
    ul {
      list-style: none;
      padding: 0;
      padding-inline-start: 0px;
      li {
        font-weight: 400;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 30px;
        }
        a {
          padding-left: 20px;
          color: @cyan;
          font-weight: 400;
          &:hover {
            color: @cyan-lighten-1;
          }
          &.active {
            font-weight: 500;
            border-left: 2px solid @cyan;
            text-decoration: none;
          }
        }
      }
    }
  }
  &__body {
    min-width: 72%;
    max-width: 72%;
    padding-left: @space * 2;
    border-left: 1px solid @snow-alt-2;
    .code-override {
      color: #005fc4;
    }
    img {
      max-width: 100%;
    }
    h1 {
      font-size: @display-size;
      font-weight: 400;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 20px;
      color: @gray-medium;
      font-weight: 400;
      code {
        .code-override();
      }
      & + &:not(pre) > code[class*="language-"],
      & + pre[class*="language-"] {
        margin-top: -10px;
        font-size: @paragraph-size;
      }
    }
    h2 {
      margin-bottom: 30px;
      font-size: 24px;
      letter-spacing: -0.22px;
      font-weight: 300;
      line-height: 32px;
      color: @gray-dark;
      margin-top: 40px;
      code {
        .code-override();
      }
    }
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
      code {
        .code-override();
      }
    }
    h4 {
      font-size: 14px;
      color: @gray-medium;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: 500;
      code {
        .code-override();
      }
    }
    a {
      color: @cyan;
      &:hover {
        color: @cyan-lighten-1;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 40px;
      font-size: @paragraph-size;
      th {
        font-weight: 500;
        color: @gray-dark;
        padding: 10px;
        text-align: left;
      }
      td {
        padding: 10px;
        color: @gray-medium;
        font-weight: 400;
        code {
          .code-override();
        }
      }
      tr {
        &:first-child {
          border-top: 1px solid @gray;
          background-color: @pale-grey;
        }
        &:not(:first-child) {
          border-bottom: 1px solid @snow-alt-2;
        }
      }
    }
    hr {
      border: 0;
      border-top: 1px solid @snow-alt-2;
      margin: 60px 0;
      height: 1px;
    }
    ul {
      margin: 0 0 10px;
      li {
        font-weight: 400;
        margin: 0 0 5px;
        & > code {
          .code-override();
        }
      }
    }
    .mobile({
      max-width: 100%;
      padding-left: 0;
      border: none;
    });
  }
}
