.DataProductButton {
  &:hover, &:focus {
    text-decoration: none;
  }
  &__icon {
    width: 33px;
    flex: 0;
    min-width: 33px;
    border-radius: 50%;
    img, svg {
      border-radius: 50%;
    }
  }
}
