@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../breakpoints.less";

.FileTransfers {
  @header-max-width: @max-width + @space + @space;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  .mobile({
    flex-wrap: wrap;
  });
  &__left {
    flex: 1 1 auto;
    display: flex;
    min-width: percentage(8/12);
    padding: @space;
    overflow-x: auto;
    .mobile({
      order: 2;
      min-width: unset;
      width: 100%;
    });
    table {
      max-width: @header-max-width * (8/12) + 168px; // 1320px -> 880px (8/12) + 168px (to match header)
      width: 100%;
      margin-left: auto;
      thead tr:first-child {
        border-top: none;
      }
      .mobile({
        max-width: unset;
      });
    }
  }
  &__right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    min-width: percentage(4/12);
    padding: @space;
    background: @pale-grey;
    border-left: 1px solid @fog;
    .mobile({
      order: 1;
      min-width: unset;
      width: 100%;
    });
    & > div {
      max-width: @header-max-width * (4/12); // 1320px -> 440px (4/12)
      width: 100%;
      .mobile({
        max-width: unset;
      });
    }
  }
}
