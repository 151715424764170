@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";

.GPGKey {
  margin-top: @mb-small;
  &__title {
    font-size: @paragraph-size;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    color: @pewter;
  }
  button, a.button {
    min-width: unset;
    display: block;
    margin-bottom: 5px;
    font-size: @paragraph-size;
    width: max-content;
  }
  p {
    font-size: @small-size;
    font-weight: normal;
    line-height: normal;
    color: @pewter;
    a {
      font-size: inherit;
      color: @cyan;
    }
  }
  &__fingerprint {
    margin: @mb-xxxsmall 0;
    padding: @small-space;
    background: @yellow;
    overflow: auto;
    &__code {
      font-size: @paragraph-size;
      font-weight: bold;
    }
  }
}
