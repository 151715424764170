.Placeholder {
  width: 100%;
  display: inherit;
  // If the browser doesn't support -webkit-background-clip, we use semi-transparent white content
  opacity: .15;

  // If it does, we clip animated linear gradient background to text shape
  @supports (-webkit-background-clip: text) {
    @background-size: 400px;
    opacity: 1;
    animation: placeholder-wave 1s infinite linear;

    &--light {
      @gradient-edge: fade(white, 18%);
      @gradient-middle: fade(white, 12%);
      background: repeating-linear-gradient(
        -30deg,
        @gradient-edge 0,
        @gradient-middle @background-size / 4,
        @gradient-edge @background-size / 2
      );
      background-size: @background-size;
      background-repeat: repeat;
      -webkit-background-clip: text;
      background-clip: text;
    }

    &--dark {
      @gradient-edge: fade(black, 18%);
      @gradient-middle: fade(black, 12%);
      background: repeating-linear-gradient(
        -30deg,
        @gradient-edge 0,
        @gradient-middle @background-size / 4,
        @gradient-edge @background-size / 2
      );
      background-size: @background-size;
      background-repeat: repeat;
      -webkit-background-clip: text;
      background-clip: text;

      img[src^="data:image/svg+xml;charset=utf8,"] {
        // Invert colors in dark placeholders
        filter: invert(1);
      }
    }

    * {
      -webkit-text-fill-color: transparent;
      color: transparent;
    }

    img {
      opacity: .15;
    }
  }
}

@keyframes placeholder-wave {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 400px;
  }
}
