@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";

.ProductNavigation {
  @item-vertical-padding: 15px;
  @item-horizontal-padding: 27px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: inset 0px -6px 6px -6px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;

  &__controls {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;

    a {
      padding: @item-vertical-padding @item-horizontal-padding;
    }

    &__back-button {
      .center-content();
      color: @gray-dark !important;
      svg {
        width: 1em;
        height: auto;
        margin-right: .8em;
        vertical-align: -2px;
        g {
          stroke: @gray-dark;
        }
      }
    }

    &__tabs {
      display: flex;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        color: @pewter;

        &.active {
          background: rgba(110, 142, 207, 0.15); // Almost identical to @custom-bg-light-blue on a light background, but allowing the shadow to peek through
        }
      }
    }
  }

  &__demyst-logo {
    margin-left: auto;
    padding: @item-vertical-padding @item-horizontal-padding;

    .logo-link {
      .center-content();

      img {
        width: 126px;
        height: 21px;
        filter: brightness(0.19) hue-rotate(141deg) saturate(32500%); // Changes white into @custom-top-nav-bg
      }
    }
  }
}
