@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";

.UseCaseButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-weight: normal;
  transition: .3s all ease-in-out;
  white-space: nowrap;
  .layered-shadow();
  &--space-between {
    justify-content: space-between;
  }

  &--disabled {
    .UseCaseButton__title {
      color: inherit;
    }
    img, svg {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  &__icon {
    width: 1.4em;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img, svg {
      width: 100%;
      height: auto;
    }
    &--left {
      margin-right: 10px;
    }
  }
  &__body {
    overflow: hidden;
  }
  &__title {
    font-size: 14px;
    line-height: 1.14;
    color: @cyan;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  &__subtitle {
    font-size: 10px;
    line-height: 1.2;
    color: @gray-medium;
  }

  &:hover, &:focus {
    .layered-shadow-darkened();
    text-decoration: none;
    &__title {
      color: darken(@cyan, 10%);
    }
  }
}
