.Container {
  display: flex;
  margin: -7px;

  & > * {
    margin: 7px !important;
  }

  & > .Container {
    margin: 0 !important;
  }

  &--allowWrap {
    flex-wrap: wrap;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--vertical-top {
    align-items: flex-start;
  }

  &--vertical-bottom {
    align-items: flex-end;
  }

  &--vertical-center {
    align-items: center;
  }

  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column;
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }
}
