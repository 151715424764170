@import (reference) "../../breakpoints.less";

.Catalog {
  width: 100%;
  text-align: center;
  padding: 80px 20px 40px;
  overflow-y: auto;
  color: white;

  &--shared {
    width: 45%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 40px;
    max-width: 610px;

    // On smaller screens, when there's not enough space to keep both / and /:id
    // paths rendered at once, hide Catalog (/ path).
    .mobile({
      display: none;
    }, @upto-tablet-landscape); // Used to be 900px
  }
}
