@import (reference) "../../styles/colors.less";

.TeamMembers {
    padding: 0;
    list-style: none;
    margin: 0;
    font-weight: normal;
    .Placeholder:not(:first-child) {
      margin-top: 10px;
    }
    li {
      &:not(:first-child) {
        margin-top: 10px;
      }
      span {
        margin-left: 10px;
        font-size: 14px;
        line-height: 1.5;
        color: @gray-medium;
      }
    }
  }