@upto-wide-screen: ~'screen and (max-width: 2560px)';
@upto-desktop: ~'screen and (max-width: 1440px)';
@upto-tablet-landscape: ~'screen and (max-width: 976px)';
@upto-tablet: ~'screen and (max-width: 768px)';
@upto-phone-landscape: ~'screen and (max-width: 600px)';
@upto-phone: ~'screen and (max-width: 480px)';
@upto-maxwidth: ~'screen and (max-width: 1240px)';

@tablet-main-menu: ~'screen and (max-width: 800px)';
@mobile-main-menu: ~'screen and (max-width: 640px)';

.mobile(@rules, @bp: @upto-tablet) {
  @media @bp {
    @rules();
  }
}
