.Root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Toastify {
  font-size: 14px;
  font-weight: 500;

  &__toast {
    margin-bottom: 16px;
  }

  &__close-button {
    width: 36px;
    height: 36px;
    min-width: 36px;
    box-shadow: none;
  }
}
