@import (reference) "../../styles/buttons.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.NoteEditor {
  margin-bottom: @large-space;
  border: solid 1px darken(@fog, 5%);
  background: @white;
  &__body {
    &--internal {
      background: @yellow;
    }
    .UserAvatar {
      .mobile({
        display: none;
      });
    }
    padding: @small-space;
    display: flex;
    align-items: flex-start;
    textarea {
      max-width: unset;
      resize: none;
      border: none;
      padding: 5px 10px 0;
      flex: 1;
      font-weight: 400;
      height: 64px;
      background-color: inherit;
    }
    .mobile({
      flex-wrap: wrap;
    });
  }
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: solid 1px darken(@fog, 5%);
    padding: @small-space/4 @small-space;

    label {
      display: inherit;
      line-height: 1;
      margin-right: 10px;
      font-size: @small-size;
      font-weight: 400;
      input[type="checkbox"] {
        margin-right: 5px;
      }
    }

    input[type="file"] {
      max-width: unset;
      width: 100%;
      border: none;
      display: inline-flex;
    }

    button {
      .layered-shadow();
      background: @cyan;
      padding: 5px 15px;
      min-width: 72px;
      min-height: 30px;

      &[disabled] {
        opacity: 0.25
      }
      .mobile({
        width: 100%;
      });
    }
  }

  &:hover {
    border-color: darken(@fog, 15%);
  }
}
