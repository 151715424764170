@import (reference) "../../helpers.less";
@import (reference) "../../styles/buttons.less";
@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/shadows.less";

.Search {
  max-width: 500px;
  min-width: 130px;
  .catalog-margin();

  input[type="search"] {
    width: 100%;
    height: 58px;
    min-width: 130px;
    max-width: 500px;
    padding: 15px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%2362769d%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E");
    background-position: calc(100% - 21px) 50%;
    background-color: @white;
    border: 1px solid @white;
    border-radius: 4px;
    .layered-shadow-darkened();
    transition: 0.3s all ease-in-out;
    font-size: 18px;
    font-weight: 500;
    color: @gray-dark;

    &::placeholder {
      color: @pewter;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &:focus, &:active, &:hover:focus, &:hover:focus {
      .layered-shadow-cyan-darkened();
      border-color: lighten(@cyan, 25%) !important;
    }
  }

  &__header {
    text-align: left;
    color: @yellow-dark;
    font-size: @small-size;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0 15px;
    margin: 15px 0 5px;
    text-transform: uppercase;
  }

  &__items {
    border-bottom: 1px solid rgba(@yellow-dark, 0.333);
    margin-bottom: 0;
    position: relative;

    &--data-sources {
      max-height: 5 * 46px;
      overflow-y: scroll;
    }
  }


  .InputWithSuggestions__items {
    .layered-shadow-darkened();
    border-radius: 0 !important;
    background: @yellow;
    min-width: 130px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: absolute !important;
    top: calc(100% - 2px) !important;
    padding: 0 !important;
    max-height: 322px !important;

    .Search__item {
      @icon-size: 26px;
      .h4();
      & > button {
        .button-as-link();
        text-transform: none !important;
        padding: 15px (15px + (@icon-size/2)) !important;
        font-size: @paragraph-size !important;

        &:hover, &:focus {
          color: darken(@cyan, 5%) !important;
        }
      }
      & > a {
        margin: 0;
        padding: 10px 15px !important;

        &:hover, &:focus {
          color: @gray-dark !important;
        }
      }
      & > a, & > button {
        display: flex !important;
        align-items: center;
        transition: 0.3s all ease-in-out;

        &:hover,
        &:focus {
          background: rgba(@yellow-dark, 0.2) !important;
        }
      }

      &__icon {
        height: @icon-size;
        width: @icon-size;
        background-color: rgba(@yellow-dark, 0.15);
        margin-right: 12px;
        border-radius: 50%;

        img,
        svg {
          width: @icon-size;
          height: @icon-size;
        }

        img {
          .circle(@icon-size);
        }
      }

      &__label {
        display: flex;
        align-items: baseline;
        max-width: 200px;

        &__name {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__source {
          font-size: 11px;
          color: @gray;
          margin-left: 14px;
        }
      }
    }
  }
}
