@import (reference) "../../breakpoints.less";

.DataProducts {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .DataProductButton {
    flex-basis: calc(50% - 12.5px); // 50% minus 12.5px for each column, with 2 columns that's a 25px gutter space between the two columns
    max-width: calc(50% - 12.5px);
    margin-bottom: 10px;
    .mobile({
      flex-basis: 100%;
      max-width: 100%;
    });
  }
}