@import (reference) "../../styles/colors.less";

.TimelineEvent {
  @left: 8em;
  position: relative;
  background: inherit;
  &__icon {
    background-color: @pewter;
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: @left;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 2px solid @snow-alt-1;
  }
  &__date {
    margin: 0.3em 0;
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    &__main {
      font-size: 14px;
      line-height: 1.5;
      text-align: right;
      color: @gray-medium;
      font-weight: normal;
    }
    &__small {
      font-size: 11px;
      line-height: 1.18;
      text-align: right;
      color: @gray-medium;
      font-weight: normal;
    }
  }
  &__title {
    margin: 0 0 5px;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0px;
    color: @gray-dark;
    margin: 0 0 5px;
  }
  &__body {
    padding: 2em 2em 0 2em;
    position: relative;
    top: -1.725em;
    left: @left;
    width: calc(100% - @left);
    margin: 0 0 50px;
    font-weight: normal;
    background: inherit;
    &--chat {
      background-color: @white;
      padding: 20px;
      left: 10em;
      width: calc(100% - 10em);
      margin-top: 2em;
      top: 0em;
      border: 1px solid @fog;
      border-radius: 4px;
      white-space: pre-wrap;
      word-break: break-word;
      &:before, &:after {
        position: absolute;
        top: 5px;
        right: 100%;
        left: -16px;
        display: block;
        width: 0;
        height: 0;
        pointer-events: none;
        content: " ";
        border-color: transparent;
        border-style: solid solid outset;
      }
      &:before {
        border-width: 8px;
        border-right-color: @fog;
      }
      &:after {
        margin-top: 1px;
        margin-left: 2px;
        border-width: 7px;
        border-right-color: @white;
      }
    }
  }
}
