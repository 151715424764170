@import (reference) "../../styles/colors.less";
@import (reference) "../../breakpoints.less";

.Navigation {
  @header-horizontal-padding: 20px;

  background-color: @midnight;
  letter-spacing: 1.17px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style: none;

  .Menu__list__item a {
    color: white;
  }

  @media @tablet-main-menu {
    position: absolute;
    top: 100%;
    left: -@header-horizontal-padding;
    right: -@header-horizontal-padding;
    padding: 1em 0;
    text-align: center;
    flex-direction: column;
    transform: scaleY(0);
    transform-origin: 0 0;

    &--open {
      transform: scaleY(1);
      animation: unsquash 400ms ease-in-out;
    }

    &--closed {
      animation: squash 400ms ease-in-out;
    }
  }

  .mobile({
    height: calc(100vh - 58px);
  }, @upto-phone-landscape); // Used to be 640px
}

@keyframes squash {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes unsquash {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}
