@import (reference) "./colors.less";

// NOTE: Always make sure to update the corresponding EDaaS Admin style file
// that maps to this one In Manta.
//
// stylesheets/edaas/mixins/_typography.scss

@super-display-size: 3.25rem; // 52px
@display-size: 2.8125rem; // 45px
@title-size: 1.5rem; // 24px
@title-size-lg: 1.875rem; // 30px
@body-size: 1rem; // 16px
@paragraph-size: 0.875rem; // 14px
@small-size: 0.75rem; // 12px

.super-display() {
  font-family: Lato;
  font-size: @super-display-size;
  line-height: 1;
  letter-spacing: -0.93px;
  color: @slate;
}

.display() {
  font-family: Lato;
  font-size: @display-size;
  line-height: 1.16;
  letter-spacing: -0.5px;
  color: @navy;
}

.title() {
  font-family: Lato;
  font-size: @title-size;
  font-weight: normal;
  line-height: 1.58;
  letter-spacing: -0.23px;
  color: @navy;
}

.h1() {
  font-family: Lato;
  font-size: 2em; // 32px
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: @navy;
}

.h2() {
  font: inherit;
  font-size: @title-size; // 24px
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: -0.22px;
  color: @gray-dark;
}

.h3() {
  font: inherit;
  font-size: @body-size; // 18px
  font-weight: 300;
  line-height: 1.5;
  color: @gray-dark;
}

.link() {
  font: inherit;
  font-size: @paragraph-size;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: inherit;
  text-decoration: none;
  color: inherit;
}

.h4() {
  font: inherit;
  font-size: @paragraph-size;
  font-weight: 500;
  line-height: 1.5;
  color: @gray-dark;
}

.paragraph() {
  font: inherit;
  font-size: @paragraph-size;
  font-weight: 400;
  line-height: 1.5;
  color: @gray-medium;
}

.code() {
  font-family: RobotoMono, "Roboto Mono", Consolas, Monaco;
  font-size: @paragraph-size;
  line-height: 1.5;
  color: @slate;
}

.small() {
  font: inherit;
  font-size: @small-size;
  line-height: 1.5;
  color: @gray-medium;
}
