@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.UseCaseTimeline {
  background: @pale-grey;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  border-left: 1px solid @fog;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__content {
    padding: @space @space @space * 3;
    width: 100%;
    max-width: (@max-width - 20)*(7/12) + (@space * 2);
  }
  &__title {
    margin-top: 0;
    flex: 1;
  }
  &__warning {
    flex: 1;
    color: @red;
    font-weight: bold;
    &__icon {
      font-size: 16px;
      line-height: 1;
    }
    img {
      width: 16px;
      height: auto;
      background: red;
    }
  }
  .UseCaseButton {
    background: @white;
    margin-top: 10px;
  }
  .mobile({
    border-bottom: 0;
    border-top: 1px solid @fog;
    &__content {
      max-width: unset;
    }
  });
  .TimelineEvent {
    &--Note-internal {
      .TimelineEvent__body {
        background: @yellow;
        &--chat {
          &:after {
            border-right-color: @yellow;
          }
          .TimelineEvent__title {
            position: relative;
            &:after {
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              pointer-events: none;
              content: "Internal";
              font-size: @small-size;
              color: @yellow-dark;
            }
          }
        }
      }
    }
  }
}
