@import (css) url("https://fonts.googleapis.com/css?family=Lato:400|Roboto:300,400,500,700|Roboto+Mono:400");

@import (reference) "./styles/buttons.less";
@import (reference) "./styles/colors.less";
@import (reference) "./styles/typography.less";
@import (reference) "./styles/inputs.less";
@import (reference) "./styles/tables.less";
@import (reference) "./helpers.less";

.marlin-styles() {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: @gray-medium;

  h1 {
    .h1();
  }
  h2 {
    .h2();
  }
  h3 {
    .h3();
  }
  h4 {
    .h4();
  }
  p {
    .paragraph();
  }
  a {
    .link();
    &:not(.button).cyan {
      color: @cyan;
    }
    &.button {
      .button();
    }
  }
  code {
    .code();
  }
  small,
  caption {
    .small();
  }

  label {
    font-weight: 500;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="search"],
    &[type="tel"],
    &[type="date"],
    &[type="number"] {
      .text-input();
    }

    &[type="search"] {
      display: block;
      margin-left: auto;
      padding-right: 24px; // Space for the icon
      background-color: @snow-alt-1;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%23424242%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: calc(100% - 9px) 50%;
      border: 1px solid @snow-alt-1;
      -webkit-appearance: none;

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }

      &.big {
        margin-left: 0;
        margin-left: unset;
        background-position: 100% 5px;
      }

      &.light {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%23fff%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E");
        color: @white;
      }
    }

    &[type="checkbox"] {
      .checkbox-input();
    }

    &[type="file"] {
      .file-input();
      // Fixing material-design.css stuff
      line-height: unset;
    }

    &[type="checkbox"],
    &[type="radio"] {
      // Fixing material-design.css stuff
      margin: 0;
      position: static;
      left: 0;
      visibility: visible;
      margin: unset;
      position: unset;
      left: unset;
      visibility: unset;
      -webkit-appearance: checkbox;
    }
  }
}

html {
  .marlin-styles();
}

body {
  min-height: 100vh;
  margin: 0;
  line-height: 1.5;
}

select {
  .dropdown-input();

  &[multiple] {
    .multiselect-input();
  }
}

textarea {
  .textarea();
}

button, a.button {
  .button();
}

table {
  .table();
}
code,
pre {
  font-family: "Roboto Mono", Consolas, Monaco, "Andale Mono", "Ubuntu Mono",
    monospace;
}

mark {
  background-color: @baby;
  padding: 1px;
}

.wrapper {
  max-width: 1140px;
  margin: 0 auto;
}

.link-button-inline {
  align-self: center;
  color: #00acc1;
}
