@import (reference) "../../styles/colors.less";

.UserAvatar {
  display: inline-block;
  background-color: @pewter;
  border-radius: 50%;
  font-weight: 400;
  color: @white;
  text-align: center;
}
