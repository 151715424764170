@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";

.HeaderWithFilters {
  .catalog-margin();
  text-align: left;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    border-bottom: 1px solid fade(@pewter-light, 25%);
  }

  &__tabs {
    @tabs-spacing: 6px;
    display: flex;
    flex-grow: 1;
    margin: -@tabs-spacing;
    padding: 0;
    font-size: inherit;
    color: inherit;
    list-style: none;

    li {
      margin: @tabs-spacing;
      padding: 6px 0;

      &[aria-selected="true"] {
        font-weight: bold;
        border-bottom: 3px solid @white;
      }

      a {
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }

  &__clearButton,
  &__toggleButton {
    min-width: 0;
    min-width: unset;
    min-height: 0;
    min-height: unset;

    background: none !important;
    box-shadow: none !important;
    outline: none;
    color: @white;

    &:enabled {
      &:hover, &:focus {
        box-shadow: none;
      }
    }

    &:disabled {
      opacity: .5;
      background: none !important;
    }
  }

  &__toggleButton {
    padding: 6px;
    font-size: 11px;

    &__arrow {
      .arrow();
    }
  }
}
