@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/spacing.less";
@import (reference) "../../../styles/typography.less";

.StaticPage {
  background: @white;
  overflow-x: hidden;

  &__hero {
    background-image: @gradient;
    padding: @section-space @space;
    &__title {
      font-size: @display-size;
      font-weight: 400;
      color: @white;
      margin: 0;
      text-align: center;
    }
  }
  &__body {
    h3 {
      margin: 40px 0 30px;
    }
    ul {
      padding-inline-start: 30px;
      li {
        .paragraph();
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
