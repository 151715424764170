@import (reference) "../../../styles/colors.less";

.Filters {
  margin: 14px 0;

  .Dropdown {
    &__label {
      border: 1px solid @pewter-light;

      &:disabled {
        background-color: transparent !important;
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
