@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.UseCasesItemPlaceholder {
  transition: .3s all ease-in-out;
  background: @snow-alt-1;
  display: flex;
  flex-direction: column;
  padding: @small-space @space;
  min-height: 210px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-basis: calc(33.333% - @small-space - @small-space);
  margin: 0 @small-space @space;
  border-radius: 12px;
  border: 2px dashed @fog;
  &__title {
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: @pewter;
  }
  &__body {
    color: @pewter;
    font-weight: 400;
    margin: @mb-xxxsmall 0;
    flex: 1 0 auto;
  }

  .mobile({
    order: 0;
    margin: 0 20px 40px;
    flex-basis: 100%;
    min-height: unset;
  });
}
