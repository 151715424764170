@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/shadows.less";

.FilterChips {
  @spacing: .6rem;

  ul {
    margin: -@spacing;
    margin-top: 1em - @spacing;
    padding: 0;
    list-style: none;

    li {
      max-width: calc(~"100% - " @spacing ~" * 2");
      display: inline-block;
      margin: @spacing;

      a {
        @item-height: 2.286rem;

        height: @item-height;
        max-width: 100%;
        display: inline-flex;
        padding: 0 1.15rem;
        border-radius: @item-height / 2;
        background-color: @pewter-light;
        color: @white;
        line-height: @item-height;
        white-space: nowrap;

        &:focus, &:hover {
          .shadow();
          background-color: @pewter;
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          width: 12px;
          margin-left: .5rem;
        }
      }
    }
  }
}
