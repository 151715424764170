@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/shadows.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../breakpoints.less";

.UseCases {
  .LayoutWithSubheader {
    background: @pale-grey;
  }

  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: -@small-space;
    margin-right: -@small-space;
  }

  &__item {
    .layered-shadow();
    transition: .3s all ease-in-out;
    background: @white;
    display: flex;
    flex-direction: column;
    padding: @small-space;
    min-height: 210px;
    justify-content: flex-start;
    flex-basis: calc(33.333% - @small-space - @small-space);
    margin: 0 @small-space @space;

    &__subtitle {
      margin-top: 2px;
    }

    &__name {
      font-family: Lato;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: @gray-dark;
      margin-bottom: 10px;
    }

    &__description {
      font-weight: normal;
      margin: 0;
      color: @gray-medium;
      word-wrap: break-word;
      word-break: break-word;
      display: inline-block;
      flex: 1 0 auto;
    }

    &__tags {
      margin: 15px -5px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .StatusTag {
        margin: 5px 5px 2.5px;
        line-height: 1;
      }
    }

    &:hover, &:focus {
      text-decoration: none;
      &__title {
        color: darken(@cyan, 10%);
      }
      .layered-shadow-darkened();
    }

    .mobile({
      margin: 0 20px 40px;
      flex-basis: 100%;
      order: 1;
    });

  }
}
