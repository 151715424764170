@import (reference) "../../../styles/helpers.less";

.ComplexTable {
  .ExpandableRow {
    &__expandButton {
      .no-shadow();
      height: 28px;
      min-width: 0;
      min-width: unset;
      min-height: 28px;
      background: none;
      margin: 0 -16px;
      padding: 0 16px;
      color: inherit;
      font-size: 10px;
    }
  }
}
