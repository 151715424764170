@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.ProductDataSource {
  width: 100%;
  text-align: center;
  padding: 80px 20px 40px;
  overflow-y: auto;
  color: white;

  &__title {
    .title();
    text-align: left;
    margin: 40px 0;
    .mobile({
      text-align: center;
    }, @upto-tablet-landscape);
  }

  &__subtitle {
    font-size: @body-size;
    text-align: left;
    color: @white;
    margin: 40px 0;
    .mobile({
      text-align: center;
    }, @upto-tablet-landscape);
  }

  &--shared {
    width: 45%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 40px;
    max-width: 610px;
    .ProductDataSource__title {
      text-align: center;
    }

    // On smaller screens, when there's not enough space to keep both / and /:id
    // paths rendered at once, hide Catalog (/ path).
    .mobile({
      display: none;
    }, @upto-tablet-landscape); // Used to be 900px
  }
}
