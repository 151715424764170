@import (reference) "./shadows.less";
@import (reference) "./typography.less";
@import (reference) "../breakpoints.less";
@import (reference) "../helpers.less";

.button() {
  .rounded-corners-small();
  .layered-shadow();
  height: auto;
  min-width: 135px;
  min-height: 36px;
  padding: 8px 15px;
  display: inline-block;
  border: none;
  background-color: @pewter;
  border: 0;
  border-radius: 2px;
  vertical-align: top;
  color: @white;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  line-height: 20px; // 36px height - 8px padding - 8px padding
  outline: none;
  transition: all 0.3s ease-in-out;

  &.cyan {
    background-color: @cyan;
    &:hover,
    &:focus {
      background-color: darken(@cyan, 10%);
    }
    &.border-only {
      background-color: transparent !important;
      border: 1px solid @cyan;
      box-shadow: none;
      color: @cyan;

      &:hover, &:focus {
        border-color: @cyan-lighten-1;
        color: @cyan-lighten-1;
      }
    }
  }

  &:disabled {
    background-color: @disabled !important;
    svg, img {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  &:enabled {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    .layered-shadow-darkened();
    background-color: darken(@pewter, 10%);
    text-decoration: none !important;
  }

  // A button that looks like a link, but will stay in line with other buttons
  &.link-button {
    .link();
    background-color: transparent;
    box-shadow: none;
    font: inherit;

    &:hover,
    &:focus {
      box-shadow: none;
      text-decoration: underline !important; // While we inherit from .link(), .button() removes the underline
    }

    svg {
      margin-right: 0.5rem;
      position: relative;
      top: 1px;
    }
  }

  // A link button looking just like <a>
  &.link-button-inline {
    .link-button();
    min-width: 0;
    min-width: unset;
    min-height: 0;
    min-height: unset;
    padding: 0;
    line-height: inherit;
  }

  &.button-as-cta {
    .button-as-cta();
  }
}

.button-as-link() {
  text-transform: uppercase;
  font-size: @body-size !important;
  color: @cyan !important;
  padding: 0 !important;
  min-width: auto !important;
  min-height: auto !important;
  font-weight: 500 !important;
  letter-spacing: 0.34px;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    text-decoration: none !important;
    color: darken(@cyan, 10%) !important;
  }
  .mobile({display: block; margin: 0 auto;}) !important;
}

.button-as-cta() {
  font-size: @body-size !important;
  background-color: @cyan !important;
  border: 1px solid @cyan !important;
  padding: 15px 20px !important;
  //letter-spacing: 0.7px;
  font-weight: 400 !important;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    text-decoration: none;
    background: darken(@cyan, 10%) !important;
    border-color: darken(@cyan, 10%) !important;
  }

  &.pewter {
    background-color: @pewter !important;
    border: 1px solid @pewter !important;
    &:hover,
    &:focus {
      background: darken(@pewter, 10%) !important;
      border-color: darken(@pewter, 10%) !important;
    }
  }
}
