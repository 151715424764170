@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/helpers.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../styles/ui.less";

.Popup {
  // This is here in order for new styles not to leak out to legacy components.
  .apply-new-styles();

  @padding: 15px;

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: 1040;

  &--open {
    display: flex;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__container {
    .shadow();
    position: relative;
    max-height: calc(~"100% - 10vw");
    width: calc(~"250px + 30vw");
    min-width: 320px - 32px;
    max-width: 800px;
    border: 0;
    background: white;
    margin: 5vw;
    padding: 0;
    overflow: auto;
    font: inherit;
    color: inherit;
  }

  &__header {
    padding: @padding/2;
    background-image: linear-gradient(to right, @gradient-2-start, @gradient-2-end);
    display: flex;
    align-items: center;

    &__back,
    &__close {
      width: 32px;
      flex-shrink: 0;

      button {
        min-width: 0 !important;
        min-width: unset !important;
        width: 32px;
        height: 32px;
        padding: 0;
        background: none;
        box-shadow: none;
        border: 0;
        cursor: pointer;

        svg {
          *[stroke]:not([stroke="none"]) {
            stroke: white;
          }

          *[fill]:not([fill="none"]) {
            fill: white;
          }
        }

        &:enabled {
          &:hover, &:focus {
            background: none;
            box-shadow: none;
            border: 0;
          }
        }
      }
    }

    h2 {
      .h1();
      flex-grow: 1;
      font-weight: 400;
      text-align: center;
      color: white;
    }

    &__back,
    h2,
    &__close {
      margin: @padding/2;
    }
  }

  &__content {
    padding: 28px;
    text-align: center;

    // TODO: Clean these styles up
    h1 {
      color: @mdb-color-lighten-2;
    }

    &--fluid {
      padding: 0;
    }
  }

  &__footer {
    padding: 28px;
    padding-top: 0;
    text-align: center;

    button + button {
      margin-left: 19px;
    }
  }
}
