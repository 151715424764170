@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";

.UseCase {
  &__header {
    padding: @small-space @space; // align with padding from body__content
    border-bottom: 1px solid @fog;
    .CMSSection__container {
      display: block;
      padding: 0;
      width: 100%;
    }
    h1 {
      .title();
      margin: 0px;
      .mobile({
        margin-bottom: @small-space;
      });
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mobile({
        flex-direction: column;
        align-items: flex-start;
      });
    }
  }
  &__body {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 80px - 77px - 36px); // full height minus header, page header, and footer height
    &__info {
      flex: 0 auto;
      display: flex;
      justify-content: flex-end;
      width: percentage(5/12);
      &__content {
        padding: @space;
        max-width: (@max-width - 40)*(5/12) + 20;
        width: 100%;
      }
    }
    .mobile({
      flex-direction: column;
      &__info {
        width: 100%;
        &__content {
          max-width: unset;
        }
      }
    });
  }
  &__description {
    font-size: 14px;
    line-height: 1.5;
    color: @gray-medium;
    font-weight: normal;
    white-space: pre-wrap;
    word-break: break-word;
  }
}