.no-shadow() {
  box-shadow: none !important;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}

.layered-shadow() {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.03),
    0 6.3px 7px -1px rgba(0, 0, 0, 0.03), 0 3.4px 6.9px 0 rgba(0, 0, 0, 0.04),
    0 1.9px 3.9px 0 rgba(0, 0, 0, 0.05), 0 1px 2.1px 0 rgba(0, 0, 0, 0.05),
    0 0.4px 0.9px 0 rgba(0, 0, 0, 0.07);
}

.layered-shadow-darkened() {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08),
    0 6.3px 7px -1px rgba(0, 0, 0, 0.08), 0 3.4px 6.9px 0 rgba(0, 0, 0, 0.09),
    0 1.9px 3.9px 0 rgba(0, 0, 0, 0.1), 0 1px 2.1px 0 rgba(0, 0, 0, 0.1),
    0 0.4px 0.9px 0 rgba(0, 0, 0, 0.12);
}

.layered-shadow-cyan-darkened() {
  box-shadow: 0 15px 30px 0 rgba(56, 159, 209, 0.18),
    0 6.3px 7px -1px rgba(56, 159, 209, 0.18), 0 3.4px 6.9px 0 rgba(56, 159, 209, 0.19),
    0 1.9px 3.9px 0 rgba(56, 159, 209, 0.2), 0 1px 2.1px 0 rgba(56, 159, 209, 0.2),
    0 0.4px 0.9px 0 rgba(56, 159, 209, 0.32) !important;
}

// Old Stingray override styles
.shadow() {
  box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.3);
}

.shadow-hover() {
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.3);
}

.shadow-small() {
  box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.5);
}

.shadow-small-hover() {
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.inset-shadow() {
  box-shadow: inset 0 2px 9px -2px rgba(0, 0, 0, 0.3);
}
