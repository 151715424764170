@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";
@import (reference) "../../styles/typography.less";

.LayoutWithSubheader {
  min-height: calc(100vh - 80px);
  background: @white;

  &__header {
    background: @white;
    padding: @small-space @space;
    border-bottom: 1px solid @fog;
    .CMSSection__container {
      display: block;
      padding: 0;
      width: 100%;
    }
    h1 {
      .title();
      margin: 0px;
    }
  }

  &__body {
    .CMSSection {
      justify-content: flex-start;
      padding: @space;

      .CMSSection__container {
        align-items: flex-start;
        width: 100%;
        padding: 0;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 40px;
      font-size: @paragraph-size;
      th {
        font-weight: 500;
        color: @gray-dark;
        padding: 10px;
        text-align: left;
      }
      td {
        .paragraph();
        padding: 10px;
        a:not(.button) {
          color: @cyan;
          font-weight: bold;
          &:hover {
            color: @cyan-lighten-1;
          }
        }
      }
      thead {
        tr {
          &:first-child {
            border-top: 1px solid @gray;
            border-bottom: none;
            background-color: @pale-grey;
          }
          th {
            padding: 12px 12px 10px;
            font-size: @small-size;
            font-weight: 500;
          }
        }
      }
      tbody {
        tr {
          &:hover {
            background-color: @snow-alt-1;
          }
        }
      }
      tr {
        border-bottom: 1px solid @snow-alt-2;
      }
    }
  }
}
