@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/helpers.less";

.Users {
  // This is here in order for new styles not to leak out to legacy components.
  .apply-new-styles();

  label {
    display: block !important;
    margin: .5em 0 !important;
  }

  input, select {
    min-width: 0;
    min-width: unset;
    max-width: none !important;
    max-width: unset !important;
    margin-bottom: .7em;
  }

  button {
    display: block !important;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 0 @margin;
  background: linear-gradient(to bottom, #171a21, #222733);
  overflow: auto;

  &__container {
    width: 100%;
    max-width: 600px;
    margin: auto 0; // Apparently, margin: auto + centered flexbox container = right align on IE
  }

  &__logo {
    margin-top: 36px;
    margin-bottom: 16px;


    img, svg {
      width: 100px;
      height: auto;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  &__card {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05),
      0 6.3px 7px -1px rgba(0, 0, 0, 0.05), 0 3.4px 6.9px 0 rgba(0, 0, 0, 0.06),
      0 1.9px 3.9px 0 rgba(0, 0, 0, 0.07), 0 1px 2.1px 0 rgba(0, 0, 0, 0.07),
      0 0.4px 0.9px 0 rgba(0, 0, 0, 0.09);
    padding: 40px;
    border-radius: 4px;
    background-color: @white;
  }

  &__footer {
    text-align: right;
    color: white;
    margin: 2em 4px 36px;

    nav {
      ul {
        margin: -.2em -.7em;

        li {
          display: inline-block;
          margin: .2em .7em;

          a {
            display: inline-block;
            color: inherit;
          }
        }
      }
    }
  }

  .preloader-wrapper {
    display: block;
    margin: 0 auto;
  }
}
