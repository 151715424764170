@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/spacing.less";

.DropFile {
  position: relative;
  display: inline-block;
  padding: @small-space;
  text-align: center;
  min-width: 230px;
  border: 1px dashed @cyan;
  background-color: #dbf0f7; // TODO: NOT IN PALETTE
  p {
    margin: 0;
  }
  input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    max-width: unset;
  }
}
