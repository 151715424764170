@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.HeaderWithSort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .attribute-catalog-margin();
  .mobile({
    flex-direction: column;
  }, @upto-phone-landscape);
  &__meta {
    span {
      font-weight: bold;
    }
  }
}
