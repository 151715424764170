@import (reference) "../../styles/colors.less";
@import (reference) "../../styles/typography.less";
@import (reference) "../../breakpoints.less";
@import (reference) "../../helpers.less";

.AttributeCatalog {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  &__wrapper {
    width: 100%;
    padding: 36px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    .mobile({
      flex-direction: column;
    }, @upto-phone-landscape);
    &__filters {
      flex: 1;
    }
    &__content {
      flex: 4;
      max-width: 880px;
      &__title {
        .attribute-catalog-margin();
        .display();
        font-weight: 400;
        font-size: 2.25rem;
        color: @gray-medium;
        span {
          margin-left: 11px;
          width: 44px;
          height: 18px;
          font-family: Roboto;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: @cyan-lighten-1;
        }
        .mobile({
          text-align: center;
          span {
            display: inline-block;
          }
        }, @upto-phone-landscape);
      }
      .Search {
        input[name="search"] {
          margin: 0 auto 0 0;
          color: @pewter;
          border: solid 1px rgba(98, 118, 157, 0.5); // @pewter with opacity
          padding-right: 36px; // Space for the icon
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2216%22%20viewBox=%220%200%2016%2016%22%3E%3Cg%20stroke=%22%237283a7%22%20stroke-width=%222%22%20fill=%22none%22%3E%3Ccircle%20cx=%227%22%20cy=%227%22%20r=%226%22%20/%3E%3Cline%20x1=%2212%22%20y1=%2212%22%20x2=%2215%22%20y2=%2215%22%20stroke-linecap=%22round%22%20/%3E%3C/g%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-position: calc(100% - 14px) 50%;
          &::placeholder {
            color: @pewter;
          }
        }
        .mobile({
          margin-top: 0;
        }, @upto-phone-landscape);
      }
      .TypedSuggestions {
        .mobile({
          text-align: center;
        }, @upto-phone-landscape);
      }
    }
    &__filler {
      flex: 1;
      .mobile({
        display: none;
      }, @upto-desktop); // Used to be 1280px
    }
  }
}
