@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";
@import (reference) "../../z-index.less";

.ProductDetails {
  @circle-size: 40px;
  @small-circle-size: 60px;
  @vertical-padding: 40px;
  @horizontal-padding: 80px;
  width: 55%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  &__logo {
    width: 20vw;
    min-width: @small-circle-size;
    min-height: @small-circle-size;
    max-width: @circle-size;
    max-height: @circle-size;

    img {
      .circle();
      width: 100%;
    }
  }

  &__header,
  &__content {
    a:not(.button) {
      color: @cyan;

      svg {
        margin-right: .5rem;
        position: relative;
        top: 1px;
      }
    }
  }

  &__header {
    position: relative; // Fallback
    position: sticky;
    top: 0;
    min-height: @circle-size + (2 * @vertical-padding);
    display: flex;
    align-items: center;
    padding: @vertical-padding @horizontal-padding;
    background-color: @white;
    .mobile({
      padding-left: 46px;
      padding-right: 14px;
    }, @upto-phone-landscape);

    h2 {
      .h1();
      font-size: 1.5rem;
      margin: 0;
    }

    p {
      margin-top: 0;
      color: @gray;
    }
  }

  &__content {
    padding: @vertical-padding @horizontal-padding;

    .mobile({
      padding-left: 14px;
      padding-right: 14px;
    }, @upto-phone-landscape);

    h3 {
      .title();
      font: inherit;
      font-size: .875rem;
      font-weight: 500;
      margin-bottom: 0;
    }
    p {
      .paragraph();
      margin-top: 0;
    }
  }

  &__close {
    position: absolute;
    top: 44px;
    left: 26px;
    min-width: 0;
    min-width: unset;
    width: 32px;
    height: 32px;
    padding: 0;
    background: none;
    box-shadow: none;
    background-color: transparent;
    line-height: 1;

    .mobile({
      left: 9px;
    }, @upto-phone-landscape);

    &:enabled {
      &:hover, &:focus {
        background: none;
        box-shadow: none;
        border: 0;
        outline: none;

        img, svg {
          opacity: .75;
        }
      }
    }
  }

  &__controls {
    flex-grow: 5;
  }

  .link-button, .link-button-inline {
    align-self: center;
  }
}
