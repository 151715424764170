@midnight: #111319;
@navy: #222733;
@slate: #42506d;
@cyan: #0d7da5;
@pewter: #62769d;
@navy-opaque: rgba(34, 39, 51, 0.75);
@gray-dark: #424242;
@gray-medium: #616161;
@fog: #dee2ea;
@snow-alt-1: #edf0f5;
@snow-alt-2: #dbdfe7;
@snow: @snow-alt-1;
@sky: #3cbded;
@baby: #9edef4;
@pewter-light: #929fba;
@white: #ffffff;
@pale-grey: #f5f7fa;
@pale-gray: @pale-grey;
@yellow: #fff7d7;
@yellow-dark: darken(#d0ac0b, 5%);
@red: #aa0746;

// covid-19 pandemic specific color
@covid19-color: #803D8D;

@gray: #ced4e0;
@disabled: #9e9e9e;
@light-blue-lighten-1: #29b6f6;
@cyan-lighten-1: #00acc1;

@gradient: linear-gradient(to bottom, @midnight, @navy);
@gradient-reverse: linear-gradient(to bottom, @navy, @midnight);
@divider-color: @snow-alt-1;
