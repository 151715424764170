:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #ECEFF6;
  margin: 0 0 40px;
  border-radius: 2px;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: 2px;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #7283A7;
  font-style: italic;

}

.token.punctuation {
  color: #616161;
}

.token.namespace {
  opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #AA0746;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #009CAF;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #B44A03;
  background: transparent;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #005FC4;

}

.token.function,
.token.class-name {
  color: #552B79;
}

.token.regex,
.token.important,
.token.variable {
  color: #DE3D3D;
} 